import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';
import { showToast } from '../../utils/Compartilhados/CustomToast';
import { DEPOSITO_JUDICIAL_MENSAGEM } from '../../utils/Compartilhados/Enun/Mensagens';

export const DepositoJudicialContext = createContext({})

const DepositoJudicialProvider = ({ children }) => {
    const [levantamento, setLevantamento] = useState([])
    const [substituicao, setSubstituicao] = useState([])
    const [identificacao, setIdentificacao] = useState([])
    const [statusFinalLevantamento, setStatusFinalLevantamento] = useState([])
    const [statusFinalSubstituicao, setStatusFinalSubstituicao] = useState([])
    const [ statusFinal, setStatusFinal ] = useState([])
    const [empresa, setEmpresa] = useState([])
    const [fee, setFee ] = useState([])
    const [urlBackEnd ] = useState(()=> process.env.REACT_APP_PACT_API )
    
    const getLevantamentos = async (dataFilter) =>{
        try {
            const response = await axios.get(`${urlBackEnd}/depositoJudicial/levantamento/buscarTodosProcessosRecuperacao`)
            .catch( (error) => {
                showToast('warning', error.message)
            })

            if(response.status === 200){
                if(dataFilter.length > 0 ){
                    const filter = response.data.filter((item)=> item.processo === dataFilter[0].processo)
                    setLevantamento(filter)
                }

                if(dataFilter.length === 0 ){
                    setLevantamento(response.data)
                }
            }

            if(response.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const getSubstituicao = async () =>{
        try {
            const response = await axios.get(`${urlBackEnd}/depositoJudicial/substituicao/buscarTodosProcessosSubstituicao`)
            .catch( (error) => {
                showToast('warning', error.message)
            } )

            if(response.status === 200){
                setSubstituicao(response.data)
            }

            if(response.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const atualizarLevantamentoPorIdProcessoEValidaArquivo = async (idProcesso, payload, nomeColuna ) => {
        try {

            const response = await axios.put(`${urlBackEnd}/depositoJudicial/levantamento/atualizarLevantamentoPorIdProcessoEValidaArquivo`,{
                idProcesso: idProcesso,
                payload: payload,
                nomeColuna: nomeColuna
            })
            .catch( (error) => {
                showToast('warning', error.message)
            } )

            if(response.status === 200){
                return                 
            }

            if(response.status !== 200){
                showToast('warning', response.data.message)
            }

        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const atualizarIdentificacaoPorIdProcessoEValidaArquivo = async (idProcesso, payload, nomeColuna ) => {
        try {
            const response = await axios.put(`${urlBackEnd}/depositoJudicial/identificacao/atualizarIdentificacaoPorIdProcessoEValidaArquivo`,{
                idProcesso: idProcesso,
                payload: payload,
                nomeColuna: nomeColuna
            })
            .catch( (error) => {
                showToast('warning', error.message)
            } )

            if(response.status === 200){
                return                 
            }

            if(response.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const atualizaSubstituicaoPorIdProcesso = async (idProcesso, payload) => {
        try {
            const response = await axios.put(`${urlBackEnd}/depositoJudicial/substituicao/atualizarSubstituicaoPorIdProcesso`,{
                idProcesso: idProcesso,
                payload: payload
            })
            .catch( (error) => {
                showToast('warning', error.message)
            } )
            
            if(response.status === 200){
                return                 
            }

            if(response.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const getIdentificacao = async (dataFilter) =>{
        try {
            const response = await axios.get(`${urlBackEnd}/depositoJudicial/identificacao/buscarTodosProcessosIdentificacao`)
            .catch( (error) => {
                showToast('warning', error.message)
            })

            if(response.status === 200){
                if(dataFilter.length > 0 ){
                    const filter = response.data.filter((item)=> item.processo === dataFilter[0].processo)
                    setIdentificacao(filter)
                }

                if(dataFilter.length === 0 ){
                    setIdentificacao(response.data)
                }
            }

            if(response.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const getStatusFinal = async () =>{
        try {
            const response = await axios.get(`${urlBackEnd}/statusFinal/all`)
            .catch( (error) => {
                showToast('warning', error.message)
            } )

            let statusLevantamento = []
            let statusSubstituicao = []
            let statusFinal = []
            if(response.status === 200){
                
                response.data.map( (item)=>{
                    if(item.aba === "DepositoJudicial_substituicao"){
                        statusSubstituicao.push(item.descricao)
                    }

                    if(item.aba === "DepositoJudicial_levantamento"){
                        statusLevantamento.push(item.descricao)
                    }

                    statusFinal.push({
                        id: item._id,
                        label: item.descricao,
                        aba: item.aba === "DepositoJudicial_substituicao"? "Substituição" : "Levantamento"
                    })
                })
                
                setStatusFinalLevantamento(statusLevantamento)
                setStatusFinalSubstituicao(statusSubstituicao)
                setStatusFinal(statusFinal)
            }

            if(response.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const getEmpresa = async () =>{
        try {
            const response = await axios.get(`${urlBackEnd}/empresa/all`)
            .catch( (error) => {
                showToast('warning', error.message)
            } )

            let _empresa = []
            if(response?.status === 200){
                
                response.data.map( (item)=>{
                    _empresa.push({
                        value: item._id,
                        label: item.descricao,
                        id: item._id
                    })
                })
                
                setEmpresa(_empresa)
            }

            if(response?.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const salvarEmpresa = async (payload) =>{
        try {
            const response = await axios.post(`${urlBackEnd}/empresa/criar`, payload)
            .catch( (error) => {
                showToast('warning', error.message)
            } )

            if(response?.status === 200){
                showToast('success', 'Empresa criada com sucesso!')
            }

            if(response?.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const getFeeLegal = async () =>{
        try {
            const response = await axios.get(`${urlBackEnd}/feeLegalInsights/all`)
            .catch( (error) => {
                return error?.response
            } )

            let _empresa = []
            if(response?.status === 200){
                
                response.data.map( (item)=>{
                    _empresa.push({
                        value: item._id,
                        label: item.descricao,
                        percentual: item.percentual,
                        id: item._id
                    })
                })
                
                setFee(_empresa)
            }

            if(response?.status === 400 && response?.data?.message === "Nenhum registro encontrado!"){
                setFee([])
            }

            if(response?.status !== 400 && response?.status !== 200){
                setFee([])
                showToast('warning', response.data.message)
            }

        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const salvarFeeLegal = async (payload) =>{
        try {
            const retorno = await axios.post(`${urlBackEnd}/feeLegalInsights/create`, payload)
            .catch( (error) => {
                return error
            } )

            if(retorno?.status === 200){
                showToast('success', retorno.data.message)
            }

            if(retorno?.status !== 200){
                showToast('warning', retorno.response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const salvarStatusFinal = async (payload) =>{
        try {
            const retorno = await axios.post(`${urlBackEnd}/statusFinal/criar`, payload)
            .catch( (error) => {
                return error
            } )

            if(retorno?.status === 200){
                showToast('success', "Registro criado com sucesso!")
            }

            if(retorno?.status !== 200){
                showToast('warning', retorno.response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const alterarEmpresa = async (payload) =>{
        try {
            const response = await axios.put(`${urlBackEnd}/empresa/alterar?id=${payload.id}&empresa=${payload.empresa}`)
            .catch( (error) => {
                showToast('warning', DEPOSITO_JUDICIAL_MENSAGEM.ERRO_ALTERAR_EMPRESA)
                console.log(error)
            } )

            if(response?.status === 200){
                showToast('success', DEPOSITO_JUDICIAL_MENSAGEM.ALTERAR_EMPRESA)
            }

            if(response?.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const atualizarFeePorEmpresa = async (payload) =>{
        try {
            const response = await axios.put(`${urlBackEnd}/feeLegalInsights/atualizarFeePorEmpresa?empresa=${payload.empresa}&percentual=${payload.percentual}`)
            .catch( (error) => {
                showToast('warning', DEPOSITO_JUDICIAL_MENSAGEM.ERRO_FEE )
                console.log(error)
            } )

            if(response?.status === 200){
                showToast('success', DEPOSITO_JUDICIAL_MENSAGEM.ALTERAR_FEE )
            }

            if(response?.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const alterarStatsFinal = async (payload) =>{
        try {
            const response = await axios.put(`${urlBackEnd}/statusFinal/alterar?id=${payload.id}&statusFinal=${payload.statusFinal}`)
            .catch( (error) => {
                showToast('warning', DEPOSITO_JUDICIAL_MENSAGEM.ERRO_STATUS )
                console.log(error)
            } )

            if(response?.status === 200){
                showToast('success', DEPOSITO_JUDICIAL_MENSAGEM.ALTERAR_STATUS )
            }

            if(response?.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const deletarFeePorEmpresa = async (id) =>{
        try {
            const response = await axios.delete(`${urlBackEnd}/feeLegalInsights/delete?id=${id}`)
            .catch( (error) => {
                console.log(error)
                return error.response
            } )

            if(response?.status === 200){
                showToast('success', DEPOSITO_JUDICIAL_MENSAGEM.DELETE_FEE )
            }

            if(response?.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const deleteStatsFinal = async (id) =>{
        try {
            const response = await axios.delete(`${urlBackEnd}/statusFinal/delete?id=${id}`)
            .catch( (error) => {
                console.log(error)
                return error.response
            } )

            if(response?.status === 200){
                showToast('success', DEPOSITO_JUDICIAL_MENSAGEM.DELETE_STATUS_FINAL )
            }

            if(response?.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const deleteEmpresa = async (id) =>{
        try {
            const response = await axios.delete(`${urlBackEnd}/empresa/delete?id=${id}`)
            .catch( (error) => {
                console.log(error)
                return error.response
            } )

            if(response?.status === 200){
                showToast('success', DEPOSITO_JUDICIAL_MENSAGEM.DELETE_EMPRESA)
            }

            if(response?.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }



    return (
        <DepositoJudicialContext.Provider value={{         
            levantamento, setLevantamento,
            substituicao, setSubstituicao,
            identificacao, setIdentificacao,
            statusFinalLevantamento, setStatusFinalLevantamento,
            statusFinalSubstituicao, setStatusFinalSubstituicao,

            getLevantamentos, getSubstituicao, 
            atualizarLevantamentoPorIdProcessoEValidaArquivo,
            atualizarIdentificacaoPorIdProcessoEValidaArquivo,
            atualizaSubstituicaoPorIdProcesso, getIdentificacao,
            getStatusFinal, getEmpresa, empresa, salvarEmpresa,
            getFeeLegal,salvarFeeLegal, fee, statusFinal, salvarStatusFinal, alterarEmpresa, atualizarFeePorEmpresa,
            alterarStatsFinal, deletarFeePorEmpresa, deleteStatsFinal, deleteEmpresa
        }}>
            {children}
        </DepositoJudicialContext.Provider>
    )
}

DepositoJudicialProvider.propTypes = {
    children: PropTypes.node,
}

export default DepositoJudicialProvider;