export const tipoUsuario = {
    PESSOA_JURIDICA: 1,
    PESSOA_FISICA: 2
}


export const aprovacoesMensagem = {
    NENHUM_PROJETO: "Nenhum projeto encotrado para o seu perfil.",
    TENTE_NOVAMENTE_MAIS_TARDE: "Não foi possível processua sua solicitação, tente novamente mais tarde.",
    USUARIO_INVALIDO: "Perfil não contém aprovações vinculadas.",
    ERRO_DURANTE_REPROVACAO: "Ocorreu um erro durante a reprovação"
}

export const editMode = {
    CELL: 'cell',
    ROW: 'row'
}