import * as React from 'react';
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'


import {
  GridRowModes,
  DataGridPro,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditDateCell,
} from '@mui/x-data-grid-pro';

import { DepositoJudicialContext } from '../../../../../contexts/ContextDepositoJudicial/DepositoJudicialContext'

import { format } from 'date-fns'
import { AuthContext } from '../../../../../contexts/LegalInsights/auth';
import { currencyFormatter } from '../../../../../utils/data';




const GridSubstituicao = ( { dataFilter } ) => {
    const [rows, setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const { substituicao, getSubstituicao, atualizaSubstituicaoPorIdProcesso, statusFinalSubstituicao, getStatusFinal } = React.useContext(DepositoJudicialContext)
    const { user } = React.useContext(AuthContext)
    const [_statusFinal, _setStatusFinal] = React.useState([])
    const [ editarGrid, setEditarGrid ] = React.useState(true)
    
    React.useEffect(()=>{
        getSubstituicao()
        getStatusFinal()
        if(user.tipoUsuario !== "Administrador Global"){
            setEditarGrid(false)
        }
    },[])

    React.useEffect(()=>{
        if(substituicao.length > 0){
            if(user.tipoUsuario !== "Administrador Global"){
                const _substituicao = substituicao.filter( (item)=> item.empresa === user.nomeCliente)
                setRows(_substituicao)
            }

            if(user.tipoUsuario === "Administrador Global"){
                setRows(substituicao)
            }
        }    
    },[substituicao])

    React.useEffect(()=>{ _setStatusFinal(statusFinalSubstituicao) },[statusFinalSubstituicao])

    const handleRowEditStop = (params, event) => () => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })        
    }

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = async (newRow) => {
        const updatedRow = { ...newRow, isNew: false }
        setRows(prevRows => prevRows.map(row => (row.id === updatedRow.id ? updatedRow : row)))   
        await atualizaSubstituicaoPorIdProcesso(newRow.id, newRow)    
        await getSubstituicao()
        return updatedRow

    };

    const handleRowModesModelChange = (newRowModesModel) => () => {
        setRowModesModel(newRowModesModel);
    };

    const handleValueGetterDate = (params) => {
        const value = params.row[params.field]
    
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {    
            const [day, month, year] = value.split("/").map(Number)
            
            const date = new Date(year, month - 1, day)            
            
            return isNaN(date.getTime()) ? null : date
        }

        if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(value)) {
            const date = new Date(value);
            return isNaN(date.getTime()) ? null : date;
        }
        
        return null
    }

    const handleValueFormatterDate = (params) => {
        if (!params.value) return ''
        return format(new Date(params.value), 'dd/MM/yyyy')
    }


    const columns = [
    { field: "id", headerName: "ID", hide: true, headerAlign: 'center' },

    {
        field: 'actions',
        type: 'actions',
        headerName: 'Editar',
        width: 100,
        pinned: 'left',
        cellClassName: 'actions-cell',
        headerClassName: 'actions-header',
        getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
            return [
            <GridActionsCellItem
                key={`${id}-save`}
                icon={<SaveIcon />}
                label="Save"
                sx={{
                    color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
                key={`${id}-cancel`}
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
            />,
            ];
        }

        return [
            <GridActionsCellItem
                key={`${id}-edit`}
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
            />,
        ]
        },
    },
    { field: 'empresa', headerName: "Empresa", headerAlign: 'center', align: 'center',  width: 200 },    
    { field: "cnpj", headerName: "CNPJ",  headerAlign: 'center', align: 'center',  width: 150 },
    { field: "autor", headerName: "Autor", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "natureza", headerName: "Natureza", headerAlign: 'center', align: 'center',  width: 150 },
    { field: "processo", headerName: "Processo", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "tribunal", headerName: "Tribunal", headerAlign: 'center', align: 'center',  width: 80  },
    { field: "comarca", headerName: "Comarca", headerAlign: 'center', align: 'center',  width: 150 },
    { field: "status", headerName: "Status", headerAlign: 'center', align: 'center',  width: 100 },
    { field: "dataDoArquivamento", headerName: "Data do arquivamento", headerAlign: 'center', align: 'center', width: 180, type: 'date' , 
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate,
        renderEditCell: (params) => (
            <GridEditDateCell 
                {...params}
            />
            ),          
    },
   
    { field: "momentoProcessual", headerName: "Momento processual", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "instancia", headerName: "Instância", headerAlign: 'center', align: 'center',  width: 100 },
    { field: "tipoConta", headerName: "Tipo de conta", headerAlign: 'center', align: 'center',  width: 150 },
    { field: "oQueFazer", headerName: "O que fazer", headerAlign: 'center', align: 'center',  width: 100 },
    { field: "dataDoDepostoAberturaContaJUD", headerName: "Data do depósito/abertura conta jud", headerAlign: 'center', align: 'center',  width: 250, type: 'date' , 
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate,
        renderEditCell: (params) => (
        <GridEditDateCell 
            {...params}
        />
            ),
    },    
    { field: "saldoAtualizado", headerName: "Saldo Atualizado", headerAlign: 'center', align: 'center',  width: 150, 
        editable: editarGrid,
        valueFormatter: (params) => {
            if (params.value == null) return "R$ 0,00"
            return currencyFormatter.format(params.value.replace(',', '.')) 
        }
    },
    { field: "dataDoSaldo", headerName: "Data do Saldo", headerAlign: 'center', align: 'center',  width: 150, type: 'date' , 
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate,
        renderEditCell: (params) => (
        <GridEditDateCell 
            {...params}
        />
            ),
    },
    { field: "valorRecuperacao", headerName: "Valor recuperação", headerAlign: 'center', align: 'center',  width: 150  },
    { field: "valorParaGestao", headerName: "Valor para gestão", headerAlign: 'center', align: 'center',  width: 150  },

    { field: "dtaOrigemDocumento", headerName: "Data origem documento", headerAlign: 'center', align: 'center', editable: editarGrid, width: 200, type: 'date' , 
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate,
        renderEditCell: (params) => (
        <GridEditDateCell 
            {...params}
        />
        ),
    },
    { field: "agencia", headerName: "Agência", headerAlign: 'center', align: 'center',  width: 80 },
    { field: "operacao", headerName: "Operação", headerAlign: 'center', align: 'center',  width: 80 },
    { field: "conta", headerName: "Conta", headerAlign: 'center', align: 'center',  width: 80 },
    { field: "dv", headerName: "Dv", headerAlign: 'center', align: 'center',  width: 50 },
    { field: "faseDoProjeto", headerName: "Fase do projeto", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "dataDoPeticao", headerName: "Data do petição", headerAlign: 'center', align: 'center', width: 150, editable: editarGrid, type: 'date' , 
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate,
        renderEditCell: (params) => (
        <GridEditDateCell 
            {...params}
        />
        ),
    },
    { field: "proximaFase", headerName: "Próxima fase", headerAlign: 'center', align: 'center',  width: 200 },
    { field: "dataDaPeticao", headerName: "Data da petição", headerAlign: 'center', align: 'center', width: 150, editable: editarGrid, type: 'date' , 
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate,
        renderEditCell: (params) => (
        <GridEditDateCell 
            {...params}
        />
        ),
    },
    { field: "numeroDaApolice", headerName: "Número da apolice", headerAlign: 'center', align: 'center',  width: 150, editable: editarGrid },
    { field: "vencimentoApolice", headerName: "Vencimento da apolice", headerAlign: 'center', align: 'center',  width: 180, editable: editarGrid },
    { field: "seguradora", headerName: "Seguradora", headerAlign: 'center', align: 'center',  width: 150, editable: editarGrid },
    { field: "valorCreditado", headerName: "Valor creditado", headerAlign: 'center', align: 'center',  width: 150, editable: editarGrid },
    { field: "dataDoCredito", headerName: "Data do credito", headerAlign: 'center', align: 'center',  width: 150, editable: editarGrid, type: 'date' ,
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate,
        renderEditCell: (params) => (
            <GridEditDateCell 
                {...params}
            />
            ),
    },
    { field: "contaCreditada", headerName: "Conta creditada", headerAlign: 'center', align: 'center',  width: 150, editable: editarGrid },
    { field: "valorConciliado", headerName: "Valor conciliado", headerAlign: 'center', align: 'center',  width: 150 },
    { field: "baixaContabil", headerName: "Baixa contábil", headerAlign: 'center', align: 'center',  width: 150 },
    { field: "controleEmpresa", headerName: "Controle empresa", headerAlign: 'center', align: 'center',  width: 150 },
    { field: "apontamentoObservacoes", headerName: "Apontamento observações", headerAlign: 'center', align: 'center',  width: 200, editable: editarGrid},
    { field: "andamentoProcessoAposProtocolo", headerName: "Adamento processo apos protocolo", headerAlign: 'center', align: 'center',  width: 400, editable: editarGrid },
    { field: "statusFinal", headerName: "Status final", headerAlign: 'center', align: 'center', editable: editarGrid, width: 200, type: 'singleSelect',
        valueOptions: ()=>{
            return _statusFinal.sort((a, b) => a.localeCompare(b))
        }
    },
    { field: "fee", headerName: "Fee", headerAlign: 'center', align: 'center',  width: 100,  
        valueFormatter: (params) => {
            if (params.value == null) return "R$ 0,00"
            return currencyFormatter.format(params.value.replace(',', '.')) 
        } 
    }
    ]

  return (
    <Box
      sx={{
        height: '80vh',
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGridPro
        rows={dataFilter.length > 0 ? dataFilter : rows }
        columns={columns}
        columnVisibilityModel={{
            id: false,
            actions: editarGrid
        }}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        sx={{
            '& .MuiDataGrid-pinnedColumnHeaders': {
              backgroundColor: '#f5f5f5',
            },
            '& .MuiDataGrid-columnHeader.actions-header': {
                backgroundColor: '#4e1975',
                color: 'white', 
                fontWeight: 'bold',
                textAlign: 'center',
            },
            '& .MuiInputBase-input': {
                  textAlign: 'center',
                  textWrap: 'wrap',
            },
            '& .MuiDataGrid-cell .MuiDataGrid-cellContent': {
                lineHeight: '1.2',
                display: 'flex',
                alignItems: 'center',
                textWrap: 'wrap',
            }
            
        }}
        initialState={{
            pinnedColumns: {
              left: ['actions'], // Garante que a coluna "actions" esteja fixada à esquerda
            },
        }}
      />
    </Box>
  );
}

GridSubstituicao.propTypes ={
    dataFilter: PropTypes.array
}

export default GridSubstituicao