import React from 'react';
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { ConfirmDeleteToast } from '../../../utils/Compartilhados/CustomToast';

const DeleteRegister = async ({ 
    idGrid, 
    updateFunction, 
    refreshFunction 
}) => {
    const idToast = toast(
        <ConfirmDeleteToast
            onConfirm={async () => {

                await updateFunction(idGrid)

                if (refreshFunction) {
                    await refreshFunction()
                }

                toast.dismiss(idToast)
            }}
            onCancel={() => {
                toast.dismiss(idToast)
            }}
        />,
        {
            autoClose: false,
            closeOnClick: false,
        }
    );
};


DeleteRegister.propTypes ={
    idGrid: PropTypes.string,
    updateFunction: PropTypes.func,
    refreshFunction: PropTypes.func
}

export default DeleteRegister