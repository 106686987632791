import React, { useContext, useEffect, useState} from 'react'


import MenuLatel from '../../../../Components/Commons/MenuLateral'
import { menuLateralOpcoesPorModulos } from '../../../../utils/Compartilhados'


import './index.css'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'
import { LegalInsightsCrudContext } from '../../../../contexts/LegalInsights/LegalInsightsCrudContext'
import Header from '../../../../Components/Commons/Header'
import DataGridCustom from '../../../../Components/Commons/DataGrid/DataGridCustom'
import { Box } from '@mui/material'
import {   diferencaEntreDataFormatada, formartarData, formatarDataRetornaHora } from '../../../../utils'
import ReactLoading from 'react-loading'




const ControleAcesso = () => {
    const { showMenuConfiguracoes, showMeuPerfil, alterColumnVisibility, columnVisibilityModel,moduloSelecionado } = useContext(LegalInsightsCrudContext) 
    const { user, usuarioConectados, controleAcesso, load } = useContext(AuthContext)
    const [_controleAcesso, _setControleAcesso ] = useState([])    

    useEffect(()=>{  
      usuarioConectados()  
      alterColumnVisibility({ id: false }) 
    },[])

    useEffect(()=>{ 
      _setControleAcesso(controleAcesso) 
    },[controleAcesso])


    const columns = [
      {
          field: "id",
          headerName: "id",
          hide: columnVisibilityModel?.id,
      },
      {
          field: "nome",
          headerName: "Nome Completo",
          width: 250,
          editable: false,
      },
      {
          field: "email",
          headerName: "E-mail",
          width: 250,
          editable: false,
      },
  
      {
          field: "dataLogin",
          headerName: "Data Login",
          width: 250,
          editable: false,
          valueFormatter: ({ value }) => value && formartarData(new Date(value))
      },
      {
        field: "horaLogin",
        headerName: "Hora Login",
        width: 250,
        editable: false,
        renderCell: (event) => event && formatarDataRetornaHora(new Date(event.row.dataLogin))
    },

      {
        field: "tempoConectado",
        headerName: "Tempo Conectado",
        width: 250,
        editable: false,
        renderCell: (event) => event && diferencaEntreDataFormatada(new Date(event.row.dataLogin), new Date())
    },

  
        
      {
          field: "conectado",
          headerName: "Usuário Conectado?",
          width: 250,
          editable: false,
          renderCell: (event) => {
            return event.row.conectado === true? 'Sim' : 'Não'
          }
      }
      ]
    return(
      <div className="modulo-projeto-acordos-usuario">
        <Header gotoPagina={'/projetoAcordo/statusReport'}/> 
          <div className="projeto-acordo-container">
            <div className="projeto-acordo-menu-left">
              <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos(moduloSelecionado, user?.perfil)}/>
            </div>
                <div className="projeto-acordo-conteudo">
                  <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >
                      <div className="data-grid-meus-processos">
                        {load? 
                            <div className="loadProcessos">
                                <ReactLoading type='spin' color='#4e1975' />
                            </div> 
                        :
                            <Box sx={{ height: '80vh', width: '100%' }}>
                                <DataGridCustom                                             
                                    column={columns} 
                                    rows={_controleAcesso} 
                                    selection={false}                                                       
                                />
                            </Box>
                        }
                      </div>
                  </div>
                </div>
          </div>
      </div>
    )
}

export default ControleAcesso
