export const DEPOSITO_JUDICIAL_MENSAGEM = {
    BUSCAR_EMPRESA: "Buscando empresas...",
    CRIAR_EMPRESA: "Empresa criada com sucesso!",
    ALTERAR_EMPRESA: "Empresa alterada com sucesso!",
    ERRO_CRIAR_EMPRESA: "Erro ao criar empresa!",
    ERRO_ALTERAR_EMPRESA: "Erro ao alterar empresa!",
    ALTERAR_FEE: "Fee alterado com sucesso!",
    ERRO_FEE: "Erro ao alterar fee!",
    ALTERAR_STATUS: "Status alterado com sucesso!",
    ERRO_STATUS: "Erro ao alterar status!",
    DELETE_FEE: "Fee deletado com sucesso!",
    ERRO_DELETE_FEE: "Erro ao deletar fee!",
    CADASTRO_FEE_CAMPOS_OBRIGATORIOS: "Preencha todos os campos obrigatórios, Empresa e Fee!",
    DELETE_STATUS_FINAL: "Status final deletado com sucesso!",
    DELETE_EMPRESA: "Empresa deletada com sucesso!",
}