
import React from 'react'
import { GridActionsCellItem } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import PropTypes from 'prop-types'
import DeleteRegister from '../DeleteRegister/Index'

const GridActionsCellItemDelete = ({ idGrid, updateFunction, refreshFunction }) => {
    
    return([<GridActionsCellItem
        key={1}
        icon={<DeleteIcon />}
        label="Excluir"
        color="inherit"
        sx={{ color: '#4e1975' }}
        onClick={()=> DeleteRegister({ idGrid: idGrid, updateFunction: updateFunction, refreshFunction: refreshFunction })}
    />])
}

GridActionsCellItemDelete.propTypes ={
    idGrid: PropTypes.string,
    updateFunction: PropTypes.func,
    refreshFunction: PropTypes.func
}

export default GridActionsCellItemDelete