import React, { useContext } from 'react'
import './style.css'

import { AuthContext } from '../../../contexts/LegalInsights/auth'
import { buscarIcon } from '../../../utils/Compartilhados'
import { LegalInsightsCrudContext } from '../../../contexts/LegalInsights/LegalInsightsCrudContext'
import { useNavigate } from 'react-router-dom'
import ShowModalConfiguracoes from '../ShowModal'
import PropTypes from 'prop-types'
import { clearModuloLocalStorage, getModuloLocalStorage } from '../../../utils/data'

const Header = ({ gotoPagina }) => {
    const { user } = useContext(AuthContext)
    const { showModalConfiguracoes, setShowModalConfiguracoes } = useContext(LegalInsightsCrudContext)
    const navegate = useNavigate()
    let moduloSelecionado = getModuloLocalStorage()

    const onClickMShowMenuConfiguracoes = () => {
        setShowModalConfiguracoes(!showModalConfiguracoes)
        moduloSelecionado = getModuloLocalStorage()
    }

    const onClickPagina = () => {
        if (gotoPagina) {
            navegate(gotoPagina)
        } else {
            navegate('/modulos')
            clearModuloLocalStorage()
        }
    };

    return (
        <div className="header-main-container">
            <div className="header-left">
                <div>
                    <img src={buscarIcon('LogoLegalInsights')} alt="" onClick={onClickPagina} />
                    <span>{moduloSelecionado}</span>
                </div>
            </div>
            <div className="header-right">
                <div className="header-icon-sino"><img src={buscarIcon('Icon1')} alt="" /></div>
                <div className="header-perfil">Olá, {user?.nome}</div>
                <div className="header-icon-configuracoes"><img src={buscarIcon('Icon3')} alt="" onClick={onClickMShowMenuConfiguracoes} /></div>
            </div>

            {showModalConfiguracoes ? <ShowModalConfiguracoes /> : <></>}
        </div>
    );
};

Header.propTypes = {
    gotoPagina: PropTypes.string,
};

export default Header
