import React, { useContext, useEffect, useState } from 'react'
import { readExcelFile } from '../../../../utils'
import Header from '../../../../Components/Commons/Header'
import MenuLatel from '../../../../Components/Commons/MenuLateral'
import { menuLateralOpcoesPorModulos } from '../../../../utils/Compartilhados'
import { LegalInsightsCrudContext } from '../../../../contexts/LegalInsights/LegalInsightsCrudContext'

import { Toolbar } from 'primereact/toolbar'
import { filtroUsuario, formatarUsuarios, tipoUsuario } from '../../../../utils/data'
import { Dropdown } from 'primereact/dropdown'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'
import { Dialog } from 'primereact/dialog'
import FileUpload from '../../../../Components/Commons/Upload'
import { InputText } from 'primereact/inputtext'
import './index.css'
import { ButtonLabelComponent } from '../../../../Components/Commons/Button'
import useForm from '../../../../hooks/useForm'
import { showToast } from '../../../../utils/Compartilhados/CustomToast'

import file from '../../../../assets/Arquivos/Modelo_Cadastro_Usuario_Em_Lote.xlsx'
import DataGridCustom from '../../../../Components/Commons/DataGrid/DataGridCustom'
import { Box } from '@mui/material';
import ReactLoading from 'react-loading'
import { DepositoJudicialContext } from '../../../../contexts/ContextDepositoJudicial/DepositoJudicialContext'
import { CrudContext } from '../../../../contexts/crud'

const CadastroUsuarioDP = () => {
    const { showMenuConfiguracoes, showMeuPerfil, criarUsuario, arquivoUpload, criarUsuarioEmLote ,
      columnVisibilityModel, alterColumnVisibility, moduloSelecionado, setArquivoUpload
    } = useContext(LegalInsightsCrudContext) 
    const { getEmpresa, empresa } = useContext(DepositoJudicialContext)

    const { user, getAllUsers, usuarios , ativarUser, removeUser, load, alterarPerfil } = useContext(AuthContext)
    const { formConteudo, setFormConteudo } = useContext(CrudContext)

    const [showModalNovoUsuario, setShowModalNovoUsuario] = useState(false)
    const [showModalCadastroEmLote, setShowModalCadastroEmLote] = useState(false)
    const [showModalCadastroUnico, setshowModalCadastroUnico] = useState(false)
    const [_usuarios, _setUsuarios] = useState([])
    const [_empresa, _setEmpresa] = useState([])

    const [usuarioAtivo, setUsuarioAtivo ] = useState(1)
    const [form, handleInputChange, clear] = useForm({
        nomeCompleto: '',
        email: '',
        telefone: '',
        celular: '',
        cnpj: '',
        nomeCliente: '',
        projeto: [],
        tipoUsuario: '',
        acessoModuloSeguros: false,
        acessoModuloProjetoAcordo: false,
        usuarioAprovador: false,
        acessoModuloGestaoDados: false,
        acessoModuloDepositoJudiciais:true,
        status: 1
      }
    )

    useEffect(()=>{
      getAllUsers('Ativo')
      getEmpresa()
      alterColumnVisibility({id: false })
    },[])

    useEffect(()=>{
      let listaUsuario = []
      if(usuarios.length > 0){
        usuarios.map(item => { 
          if(item.perfil.acessoModuloDepositoJudiciais === 'Sim'){
            listaUsuario.push(item)
          }
        })  
      }
      _setUsuarios(listaUsuario)
    },[usuarios])

    useEffect(()=>{
      _setEmpresa(empresa)
    },[empresa])

    useEffect(()=>{
      getAllUsers(form.status === 1? "Ativo" : "Inativo")
      setUsuarioAtivo(form.status === 1? 1 : 0)
    },[form.status])

    const rightToolbarTemplate = () => {
        return (
          <React.Fragment>          
            <ButtonLabelComponent label={'Novo'} icon="pi pi-plus" onClick={onClickToobarRight} />                                    
            {usuarioAtivo === 1 && <ButtonLabelComponent label="Inativar" icon="pi pi-trash" style={{border:'none'}}  onClick={() => ativarOuInativarUsuario("Inativar")} info={'info'}/>}
            {usuarioAtivo === 0 && <ButtonLabelComponent label="Ativar" icon="pi pi-check"  onClick={() => ativarOuInativarUsuario("Ativar")} info={'alert'} />}
          </React.Fragment>
        )
    }

    const ativarOuInativarUsuario = async (statusUsuario) => {
        const _ids = []
        if(statusUsuario === 'Ativar'){          
          if(formConteudo?.length > 0){
            formConteudo.map(item => _ids.push(item))
            await ativarUser(_ids)
            setFormConteudo()
          }else{
            return showToast('info','É necessário selecionar um usuário!')
          }
        }

        if(statusUsuario === 'Inativar'){
          if(formConteudo?.length > 0){
            formConteudo.map(item => _ids.push(item))
            await removeUser(_ids)
            setFormConteudo()
          }else{
            return showToast('info','É necessário selecionar um usuário!')
          }          
        }
    }

    const leftToolbarTemplate = () => {
        return (
          <React.Fragment>
            <span className='page-title'>Filtro Status: </span>
            <Dropdown value={form.status} options={filtroUsuario} name='status' placeholder="Status" onChange={handleInputChange} id={form.status}/>            
          </React.Fragment>
        )
    }

    const onHideNovoUsuario = () => {
      setShowModalNovoUsuario(!showModalNovoUsuario)
    }

    const onClickToobarRight = () => {
      setShowModalNovoUsuario(!showModalNovoUsuario)
    }

    const onClickNovoUsuarioEmLote = () => {
      setShowModalCadastroEmLote(!showModalCadastroEmLote)
    }

    const onClickNovoUsuarioUnico = () => {
      setshowModalCadastroUnico(!showModalCadastroUnico)
    }

    const onHideNovoUsuarioEmLote = () => {
      setShowModalCadastroEmLote(!showModalCadastroEmLote)
    }

    const onHideNovoUsuarioUnico = () => {
      setshowModalCadastroUnico(!showModalCadastroUnico)
    }


    const showModalCadastroNovoUsuario = () => {
        return(<Dialog
          visible={showModalNovoUsuario}
          style={{ width: '450px' }}
          header="Cadastro de Usuário"
          modal
          className='show-modal-cadastro-novo-usuario'
          onHide={onHideNovoUsuario}
          footer={
            <React.Fragment>
              <div className="dialog-show-modal-cadastro-novo-usuario footer">
                <ButtonLabelComponent label={'Em lote'} onClick={onClickNovoUsuarioEmLote} />
                <ButtonLabelComponent label={`Unitário`} onClick={onClickNovoUsuarioUnico} /> 
              </div>  
            </React.Fragment>                  
          }
        >
            <div className="dialog-show-modal-Cadastro-novo-usuario p-dialog-content">
              Como deseja realizar o cadastro?
            </div>          
        </Dialog>)
    }

    const salvarNovoUsuario = async () => {
      const response = await criarUsuario(form)
      
      if(response?.status === 200){
        clear()
        onHideNovoUsuarioUnico()
        onHideNovoUsuario()
      }
    }

    const processarArquivoExcel = async () => {

      if(!arquivoUpload) return 

      if(arquivoUpload?.length === 0){
        showToast('warning','Nenhum arquivo selecionado!') 
        return
      } 
      
      const importacaoEmLoteUsuarios = await readExcelFile(arquivoUpload, "")
      
      if (importacaoEmLoteUsuarios.length === 0) return

      // Validação do formato do arquivo
      const colunasEsperadas = [
        "Tipo de Usuário",
        "Empresa",
        "CNPJ",
        "Nome Completo do Usuário",
        "E-mail do Usuário"
      ];

      const colunasArquivo = Object.keys(importacaoEmLoteUsuarios[0] || {})

      const colunasFaltando = colunasEsperadas.filter(coluna => !colunasArquivo.includes(coluna))
      const colunasDesconhecidas = colunasArquivo.filter(coluna => !colunasEsperadas.includes(coluna))

      if (colunasFaltando.length > 0) {
        showToast('error',`Erro: O arquivo está incorreto. Faltando as colunas: ${colunasFaltando.join(", ")}`)
      }

      if (colunasDesconhecidas.length > 0) {
          console.warn(`Atenção: O arquivo contém colunas desconhecidas: ${colunasDesconhecidas.join(", ")}`)
      }

      if (importacaoEmLoteUsuarios.length === 0) {
        showToast('warning',`Atenção: O arquivo está vazio.`)
        return
      }

      // Validação do Tipo de Usuário
      const tiposPermitidos = ["Cliente", "Administrador Global"]
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const errosUsuarios = [];

      importacaoEmLoteUsuarios.forEach((item, index) => {
          if (!tiposPermitidos.includes(item["Tipo de Usuário"])) {
              errosUsuarios.push(`Linha ${index + 2}: Tipo de Usuário inválido -> "${item["Tipo de Usuário"]}"`)
          }

          if (!regexEmail.test(item["E-mail do Usuário"])) {
            errosUsuarios.push(`Linha ${index + 2}: E-mail inválido -> "${item["E-mail do Usuário"]}"`);
          }
      })

      if (errosUsuarios.length > 0) {

          showToast('warning',`Não foi possível processa o arquivo, a coluna Tipo de Usuário ou E-mail de usuário contém valores inválidos.
          Tipo de usuáirio permitido ${tiposPermitidos.join(", ")}`)
        
          errosUsuarios.forEach(erro => console.error(erro))
          setArquivoUpload()
          return
      }

      const usuarios = formatarUsuarios(importacaoEmLoteUsuarios)

      await criarUsuarioEmLote(usuarios)

      onHideNovoUsuarioEmLote()
      onHideNovoUsuario()

      await getAllUsers('Ativo')


    }



    const showModalCadastroNovoUsuarioEmLote = () => {
      return(
        <Dialog
          visible={showModalNovoUsuario}
          style={{ width: '450px' }}
          header="Cadastro de Usuário em lote"
          modal
          className='show-modal-cadastro-novo-usuario-em-lote'
          onHide={onHideNovoUsuarioEmLote}
          footer={            
            <React.Fragment>
              <div className="dialog-show-modal-cadastro-novo-usuario-em-lote footer">
                <ButtonLabelComponent label="Processar" icon="pi pi-cloud-upload" onClick={processarArquivoExcel} /> 
                <ButtonLabelComponent label="Cancelar" icon="pi pi-times-circle" onClick={onHideNovoUsuarioEmLote} info={'info'} />
              </div>  
            </React.Fragment>   
          }
        >
          <div className='dialog-show-modal-cadastro-novo-usuario-em-lote conteudo'>
            <a href={`${file}`} target='_blank' rel="noreferrer" download>Baixar arquivo modelo</a>

              <FileUpload  textoDoUpload={<>
                <span>Arraste</span> o arquivo aqui até <br/>
                o retângulo ou<br/>
                <span>clique aqui e escolha</span> o arquivo <br />
                para fazer upload
                </>}
              /> 
          </div>              
        </Dialog>
      )
    }

    const showModalCadastroNovoUsuarioUnico = () => {
      return(<Dialog
        visible={showModalCadastroUnico}
        style={{ width: '450px' }}
        header="Cadastro de usuário unitário"
        modal
        className='show-modal-cadastro-novo-usuario'
        onHide={onHideNovoUsuarioUnico}
        footer={            
          <React.Fragment>
            <div className="dialog-show-modal-cadastro-novo-usuario-unico footer">
              <ButtonLabelComponent label="Salvar" icon="pi pi-save" onClick={() => salvarNovoUsuario()} /> 
              <ButtonLabelComponent label="Cancelar" icon="pi pi-times-circle" onClick={onHideNovoUsuarioUnico} info={'info'} /> 
            </div>  
          </React.Fragment>  
        }
      >
          <div className='dialog-show-modal-cadastro-novo-usuario-unico form'>
            <InputText className='input-textLabel' name='nomeCompleto' placeholder='Nome Completo' value={form.nomeCompleto} onChange={handleInputChange} />
            <InputText className='input-textLabel' name='email' placeholder='E-mail' value={form.email} onChange={handleInputChange} />
            <InputText className='input-textLabel' name='telefone' placeholder='Telefone' value={form.telefone} onChange={handleInputChange} />
            <InputText className='input-textLabel' name='celular' placeholder='Celular' value={form.celular} onChange={handleInputChange} />
            <InputText className='input-textLabel' name='cnpj' placeholder='CNPJ' value={form.cnpj} onChange={handleInputChange} />
            <Dropdown className='perfil-tipoUsuario' name='empresa' placeholder="Empresa" value={form.empresa} options={_empresa} onChange={handleInputChange} /> 
            <Dropdown className='perfil-tipoUsuario' name='tipoUsuario' placeholder="Tipo de usuário" value={form.tipoUsuario} options={tipoUsuario} onChange={handleInputChange} /> 
          </div>          
      </Dialog>)
    }

    const columns = [
      {
          field: "id",
          headerName: "id",
          hide: columnVisibilityModel?.id,
          width: 250,
      },
      {
          field: "name",
          headerName: "Nome Completo",
          width: 250,
          editable: true,
          renderEditCell: (params)=> <EditTexto {...params} />
      },
      {
          field: "email",
          headerName: "E-mail",
          width: 250,
          editable: false,
      },
  
      {
          field: "telefone",
          headerName: "Telefone",
          width: 250,
          editable: true,
          renderEditCell: (params)=> <EditTexto {...params} />
          
      },
      {
        field: "celular",
        headerName: "Celular",
        width: 250,
        editable: true,        
        renderEditCell: (params)=> <EditTexto {...params} />
    },

      {
        field: "cnpj",
        headerName: "CNPJ",
        width: 250,
        editable: true,        
        renderEditCell: (params)=> <EditTexto {...params} />
    },        
      
    {
      field: "nomeCliente",
      headerName: "Empresa",
      width: 250,
      editable: true,
      renderEditCell: (params)=> <EditTexto {...params} />
    },

    {
      field: "tipoUsuario",
      headerName: "Tipo de Usuário",
      width: 250,
      editable: false,
    },
    ]

    const EditTexto = (params) => {
      const [value, setValue] = React.useState(params.value);
    
      const handleChange = (event) => {
        setValue(event.target.value);
      };
    
      const handleBlur = async (event) => {
        const newValue = event.target.value;
        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue }, event);
        const paramsData = { id: params.id, field: params.field, value: newValue };
        await alterarPerfil(paramsData);
      };
    
      return (
        <input
          type="text"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          style={{ width: '100%', height: '100%', border: 'none' }}
        />
      );
    };
    

    return(
      <div className="modulo-projeto-acordos-usuario">
        <Header gotoPagina={'/depositoJudicial/statusReport'}/> 
          <div className="projeto-acordo-container">
            <div className="projeto-acordo-menu-left">
              <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos(moduloSelecionado, user?.perfil)}/>
            </div>
                <div className="projeto-acordo-conteudo">
                  <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >

                      <Toolbar className="mb-4" left={<span className='page-title'>Cadastro de Usuário</span>} right={rightToolbarTemplate}></Toolbar>

                      <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

                      <div className="data-grid-meus-processos">
                        {load? 
                            <div className="loadProcessos">
                                <ReactLoading type='spin' color='#4e1975' />
                            </div> 
                        :
                            <Box sx={{ height: '72vh', width: 'auto' }}>
                                <DataGridCustom                                             
                                    column={columns} 
                                    rows={_usuarios} 
                                    selection={true}
                                />
                            </Box>
                        }
                      </div>
                  </div>

                  {showModalNovoUsuario && showModalCadastroNovoUsuario()} 

                  {showModalCadastroEmLote && showModalCadastroNovoUsuarioEmLote()}

                  {showModalCadastroUnico && showModalCadastroNovoUsuarioUnico()}
                </div>
          </div>
      </div>
    )
}

export default CadastroUsuarioDP
