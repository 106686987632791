import React, { useContext, useEffect, useState} from 'react'


import MenuLatel from '../../../../Components/Commons/MenuLateral'
import { menuLateralOpcoesPorModulos } from '../../../../utils/Compartilhados'


import './index.css'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'
import { LegalInsightsCrudContext } from '../../../../contexts/LegalInsights/LegalInsightsCrudContext'
import Header from '../../../../Components/Commons/Header'
import DataGridCustom from '../../../../Components/Commons/DataGrid/DataGridCustom'
import { Box } from '@mui/material'
import ReactLoading from 'react-loading'
import { ButtonLabelComponent } from '../../../../Components/Commons/Button'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import useForm from '../../../../hooks/useForm'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { DepositoJudicialContext } from '../../../../contexts/ContextDepositoJudicial/DepositoJudicialContext'
// import { GridActionsCellItem } from '@mui/x-data-grid'
// import DeleteIcon from '@mui/icons-material/DeleteOutlined'
// import DeleteRegister from '../../../../Components/Commons/DeleteRegister/Index'
import GridActionsCellItemDelete from '../../../../Components/Commons/GridActionsCellItemDelete'
import { showToast } from '../../../../utils/Compartilhados/CustomToast'
import { DEPOSITO_JUDICIAL_MENSAGEM } from '../../../../utils/Compartilhados/Enun/Mensagens'




const CasdastroFee = () => {
  const { 
    showMenuConfiguracoes, 
    showMeuPerfil, 
    alterColumnVisibility, 
    columnVisibilityModel, 
    moduloSelecionado
  } = useContext(LegalInsightsCrudContext) 
  const {getEmpresa, empresa, salvarFeeLegal, getFeeLegal , fee, atualizarFeePorEmpresa, deletarFeePorEmpresa} = useContext(DepositoJudicialContext)
  const { user, load } = useContext(AuthContext) 
  const [showModal, setShowModal] = useState(false)
  const [_empresa, _setEmpresa] = useState([])
  const [_fee, _setFee] = useState([])
  const [form, handleInputChange, clear] = useForm({
    empresa: '',
    fee: ''
  }
  )

  useEffect(()=>{   
    getEmpresa() 
    getFeeLegal()
    alterColumnVisibility({ id: false }) 
  },[])

  useEffect(()=>{  
    if(empresa?.length === 0 || empresa === undefined){
      _setEmpresa([]) 
    }else{
      _setEmpresa(empresa)
    }

    if(fee?.length === 0 || fee === undefined){
      _setFee([]) 
    }else{
      _setFee(fee)
    }

  },[empresa, fee])


  const columns = [
    {
      field: "id",
      headerName: "id",
      hide: columnVisibilityModel?.id,
    },
    { 
      field: 'actions',
      type: 'actions',
      headerName: 'Deletar registro',
      width: 150,
      cellClassName: 'actions-cell', // Classe para personalizar alinhamento
      getActions: ({ id }) => GridActionsCellItemDelete({ idGrid: id, updateFunction: deletarFeePorEmpresa, refreshFunction: getFeeLegal })
    },
    {
      field: "label",
      headerName: "Empresa",
      width: 450,
      editable: false,
      cellClassName: 'label-cell', // Classe para personalizar alinhamento
    },
    {
      field: "percentual",
      headerName: "Fee",
      width: 250,
      editable: true,
      label: 'Fee',
      renderEditCell: (params) => <EditTexto {...params} />,
      cellClassName: 'percentual-cell', // Classe para personalizar alinhamento
    }
  ];

  const EditTexto = (params) => {
      const [value, setValue] = React.useState(params.value || "")
    
      const handleChange = (event) => {
        setValue(event.target.value);
      }
    
      const handleBlur = async (event) => {
        const newValue = event.target.value
    
        if (params && params.api && params.id && params.field) {
          try {
            params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue }, event)
            
            await atualizarFeePorEmpresa({ empresa: params.row.label, percentual: newValue })
            // Atualiza no backend
            // await alterarEmpresa({ id: params.id, empresa: newValue });
    
          } catch (error) {
            console.error("Erro ao atualizar a célula:", error)
          }
        } else {
          console.error("Parâmetros inválidos fornecidos para handleBlur:", params)
        }
      }
    
      return (
        <input
          type="text"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          style={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%', 
            height: '100%', 
            border: 'none', 
            textAlign: 'center'
          }}
          className='percentual-cell'
        />
      )
    }

  const rightToolbarTemplate = () => {
      return (
        <React.Fragment>          
          <ButtonLabelComponent label={'Novo'} icon="pi pi-plus" onClick={onHideModalFee}/>                                    
        </React.Fragment>
      )
  }

  const showModalCadastroFee = () => {
      return(<Dialog
        visible={showModal}
        style={{ width: '450px', height: '350px' }}
        header="Cadastro de Fee"
        modal
        className='show-modal-cadastro-novo-usuario'
        onHide={onHideModalFee}
        footer={
          <React.Fragment>
            <div className="dialog-show-modal-cadastro-novo-usuario footer">
              <ButtonLabelComponent label={`Salvar`} onClick={salvarFee} /> 
               <ButtonLabelComponent label="Cancelar" icon="pi pi-times-circle" onClick={onHideModalFee} info={'info'} /> 
            </div>  
          </React.Fragment>                  
        }
      >
          <div className="dialog-show-modal-cadastro-novo-usuario-unico form">
            <Dropdown className='perfil-tipoUsuario' name='empresa' placeholder="Empresa" value={form.empresa} options={_empresa} onChange={handleInputChange} />
            <InputText type='number' className='input-textLabel' name='fee' placeholder='Fee' value={form.fee} onChange={handleInputChange} />
          </div>          
      </Dialog>)
  }     
  
  const onHideModalFee= () => {
    setShowModal(!showModal)
    clear()
  }

  const salvarFee = async () => {
    const { empresa, fee } = form

    if(empresa === '' || fee === ''){
      return showToast('warning', DEPOSITO_JUDICIAL_MENSAGEM.CADASTRO_FEE_CAMPOS_OBRIGATORIOS)
    }

    let _empresaNome = _empresa.filter(item => item.value === empresa)[0].label
    const payload = {
      empresa: _empresaNome,
      percentual: fee
    }
    await salvarFeeLegal(payload)
    onHideModalFee()
    getFeeLegal()
  }

  const handleProcessRowUpdate = (newRow) => {
    return newRow
  };


    return(
      <div className="modulo-projeto-acordos-usuario">
        <Header gotoPagina={'/depositoJudicial/statusReport'} /> 
          <div className="projeto-acordo-container">
            <div className="projeto-acordo-menu-left">
              <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos(moduloSelecionado, user?.perfil)}/>
            </div>
                <div className="projeto-acordo-conteudo">           
                  <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >
                    <Toolbar className="mb-4" left={<span className='page-title'>Cadastro de Fee</span>} right={rightToolbarTemplate}></Toolbar>

                      <div className="data-grid-meus-processos">
                        {load? 
                            <div className="loadProcessos">
                                <ReactLoading type='spin' color='#4e1975' />
                            </div> 
                        :
                            <Box sx={{ height: '80vh', width: '100%' }}>
                                <DataGridCustom                                             
                                    column={columns} 
                                    rows={_fee} 
                                    selection={false}      
                                    processRowUpdate={handleProcessRowUpdate}                                                 
                                />
                            </Box>
                        }
                      </div>

  
                  </div>
                </div>

                {showModal && showModalCadastroFee()}
          </div>
      </div>
    )
}

export default CasdastroFee
