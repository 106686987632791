import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { showToast } from '../../utils/Compartilhados/CustomToast'
import axios from 'axios'
import { convertToBase64 } from '../../utils'
import { AuthContext } from './auth'
import { consoleTable } from '../../utils/Compartilhados'
import { aprovacoesMensagem } from '../../utils/Compartilhados/Enun'
import { tipoUsuario } from '../../utils/data'

export const LegalInsightsCrudContext = createContext({})

const LegalInsightsProvider = ({ children }) => {
    const [api] = useState(process.env.REACT_APP_PACT_API)
    const [stepEtapa, setStepEtapa] = useState(1)
    const [showMenuConfiguracoes, setShowMenuConfiguracoes] = useState(false)
    const [showMeuPerfil, setShowMeuPerfil] = useState(false)
    const [menuSelecionado, setMenuSelecionado] = useState('')
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [loading, setLoading] = useState(false)
    const [apiCidades, setApiCidades] = useState([])
    const [apiProjetos, setApiAprojetos] = useState([])
    const [apiAprovacoesPendentes, setApiAprovacoesPendente] = useState([])
    const [apiAprovacoesConcluidas, setApiAprovacoesConcluidas] = useState([])
    const [apiGetConfigPortal, setGetConfigPortal] = useState({ CodigoReport: "", Token: "", EmbeddedUrl: ""})
    const [observacoes, setObservacoes] = useState('')
    const [columnVisibilityModel, setColumnVisibilityModel] = useState()
    const [statusReportsAcesso, setStatusReportsAcesso] = useState(false)
    const [filters, setFilters] = useState([])
    const [showModalConfiguracoes, setShowModalConfiguracoes] = useState(false)
    const [arquivoUpload, setArquivoUpload] = useState([])
    const [projetosRLANET, setProjetosRLANET] = useState([])
    const [usuarioTemPowerBI, setUsuarioTemPowerBI] = useState(false)
    const [arquivos, setArquivos] = useState([])
    const [modulos, setModulo] = useState([])
    const [moduloSelecionado, setModuloSelecionado] = useState('')
    

    const { user } = useContext(AuthContext)
    const chaveRLANET = "DASLMD2134**99adsanYASMcasdasas221@#####"
    
    const enviarCodigoSeguranca = async ( email, metodo ) => {
        try{
            const data = await axios.post(`${api}/seguranca/enviarCodigo`, { email: email, metodo:  metodo })
            .then( response => { 
                return response
                // showToast('success', `Código enviado para o e-mail: ${email}`)
            })
            .catch( error => { 
                return error
                // console.log("enviarCodigoSegunraca | POST-Error: ", error) 
            })

            return data
            
        } catch (error) {
            console.log('Método: enviarCodigoSegunraca |:() ', error)
            showToast('error', 'Não foi possível enviar o código de segurança!')
        }
    }

    const validarCodigoSeguranca  = async (email, codigoSeguranca ) => {
        try {
            const data = await axios.post(`${api}/seguranca/validarCodigo`, { email: email, codigoSeguranca: codigoSeguranca })
            .then( response => { 
                console.log(response)
                showToast('success', `Código de segurança validado com sucesso!`)
                return { statusCode: 200, message: 'Código de segurança validado com sucesso!'}
            })
            .catch( error => { 
                console.log("validarCodigoSeguranca | POST-Error: ", error) 
                showToast('error', error.response.data.message)
                return { statusCode: 400, message: error.response.data.message}
            })
            return data
        } catch (error) {
            console.log('Método: validarCodigoSeguranca | ', error)
            showToast('error', 'Não foi possível validar o código de segurança!')
        }
    }

    const alterPasswordLegalInsights = async (email, novaSenha, confirmarSenha ) => {
        try {
            const payload = {
                email,
                novaSenha,
                confirmarSenha
            }
            const data = await axios.put(`${api}/user/alterPasswordLegalInsights`, payload)
            .then( response => { 
                console.log(response)
                showToast('success', response.data.message)
                return { message: response.data.message, statusCode: 200 }
            })
            .catch( error => { 
                console.log("alterPasswordLegalInsights | POST-Error: ", error) 
                showToast('error', error.response.data.message)
                return { message: error.response.data.message, statusCode: 400 }
            })

            return data
        } catch (error) {
            console.log('Método: alterPasswordLegalInsights | ', error)
            showToast('error', 'Não foi possível alterar a senha!')
        }  
    }

    const createUserLegalInsights = async (payload) => {
        try {

            const data = await axios.post(`${api}/user/createUserLegalInsights`, payload)
            .then( response => { 
                console.log(response)
                showToast('success', 'Usuário cadastrado com sucesso!')
                return { message: response.data.message, statusCode: 200 }
            })
            .catch( error => { 
                console.log("createUserLegalInsights | POST-Error: ", error) 
                showToast('error', error.response.data.message)
                return { message: error.response.data.message, statusCode: 400 }
            })

            return data
        } catch (error) {
            console.log('Método: createUserLegalInsights | ', error)
            showToast('error', 'Não foi possível criar seu usuário!')
        } 
    }

    const usuarioExistente = async (email) => {
        try {
            const payload = { 
                email: email
            }
            const response = await axios.post(`${api}/user/findUserByEmail`, payload)
            .then( response => { 
                return { message: response.data.message, statusCode: 200 } 
            })
            .catch( error =>{ 
                return showToast('error', error.response.data.message) 
            })
            return response
        } catch (error) {
            console.log('Método: usuarioExistente | ', error)
            showToast('error', 'Ocorreu um erro interno, favor contatar o adm do sistema!')
        }
    }

    const consultarProjetosRLANET = async () => {
        const tokenUsuario = {
            Email: "master",
            TokenUsuario: chaveRLANET
        }
        const jsonBase64 = convertToBase64(tokenUsuario)
        
        try {
            const response = await axios.get(`${api}/aprovacoes/projetos?tokenUsuario=${jsonBase64}`)
            .then( response =>{
                return response
            })
            .catch( error => {
                    return error.response
            }).finally(()=> setLoading(false))

            if(response?.status === 200){
                setProjetosRLANET(response?.data)
            }
            
        } catch (error) {
            console.table([{metodo: 'consultarProjetosRLANET: ', statusCode: error.statusCode? error.statusCode : error.response?.status? error.response.status : 500, message: error.response?.data? error.response.data : error.message }])
            setProjetosRLANET([])
            setLoading(false)
            return showToast('info',error.response?.data)
        }        
    }

    const aprovacaoAprovacoesPendentes = async () => {
        const tokenUsuario = {
            Email: user.email,
            TokenUsuario: chaveRLANET
        }
        const jsonBase64 = convertToBase64(tokenUsuario)
        try {
            const response = await axios.get(`${api}/aprovacoes/aprovacoesPendentes?tokenUsuario=${jsonBase64}`)
            .then( response =>{
                return response
            })
            .catch( error => {
                    return error.response
            })
    
            if(response?.status === 200 & response?.data.length === 0){
                setApiAprovacoesPendente([]) 
                consoleTable('aprovacaoAprovacoesPendentes', response)
            }
    
            if(response?.status === 400 & response.statusText === 'Bad Request'){                
                setApiAprovacoesPendente([])
                consoleTable('aprovacaoAprovacoesPendentes_linha_198', response)            
            }
    
            if(response?.status === 200 & response.data.status !== 500 ){
                
                let aprovacoes = []            
                
                response?.data?.map( (item, index)=>{
                    aprovacoes.push({
                        id: index,
                        calcDoc: item.calcDoc,
                        discDoc: item.discDoc,
                        processoID: item.processoID,
                        processoNr: item.processoNr,
                        fase: item.fase,
                        valorContingenciaAtual: item.valorContingenciaAtual,
                        valorMeta: item.valorMeta,
                        valorAcordo: item.valorAcordo,
                        valorAnalise: item.valorAnalise,
                        desagio: item.desagio,
                        valorAcordoLiquido: item.valorAcordoLiquido,
                        inssReclamada: item.inssReclamada,
                        custasProcessuais: item.custasProcessuais,
                        irrf: item.irrf,    
                        aprovado: false,
                        cancelado: false,
                        documentos: [{ link: item?.calcDoc, nomeDocumento: 'Cálculo'}, { link: item?.discDoc, nomeDocumento: 'Discriminação' }],
                        statusAprovacao: item.statusAprovacao,
                        checklist: item.checklist,
                        grupo: item.grupo,
                        grupoID: item.grupoID,
                        contraparte: item.contraparte,
                        momentoProcessual: item.momentoProcessual,
                        advogadoContraparte: item.advogadoContraparte,
                        valorProvisaoAtualizado: item.valorProvisaoAtualizado,
                        dataDecisaoCliente: item.dataDecisaoCliente
                    })
                })

                setApiAprovacoesPendente(aprovacoes)
            } 
        } catch (error) {
            console.table([{metodo: 'aprovacaoAprovacoesPendentes - aprovar: ', statusCode: error.statusCode? error?.statusCode : error.response?.status? error.response?.status : 500, message: error.response.data? error.response.data : error.message }])
        }
    }

    const aprovacaoAprovar = async (numeroProcesso, observacoes) => {
        const tokenUsuario = {
            Email: user.email,
            TokenUsuario: chaveRLANET
        }
        try {
            const jsonBase64 = convertToBase64(tokenUsuario)
            const response = await axios.post(`${api}/aprovacoes/aprovar?numeroProcesso=${numeroProcesso}&observacao=${observacoes}&tokenUsuario=${jsonBase64}`)    
            return response
        } catch (error) {
            console.table([{metodo: 'aprovacaoAprovar - aprovar: ', statusCode: error.statusCode? error.statusCode : error.response.status? error.response.status : 500, message: error.response.data? error.response.data : error.message }])
        }
    }

    const aprovacaoReprovar = async (numeroProcesso, observacoes) => {
        const tokenUsuario = {
            Email: user.email,
            TokenUsuario: chaveRLANET
        }
        const jsonBase64 = convertToBase64(tokenUsuario)
        try {
            const response = await axios.post(`${api}/aprovacoes/reprovar?numeroProcesso=${numeroProcesso}&observacao=${observacoes}&tokenUsuario=${jsonBase64}`)
            return response    
        } catch (error) {
            consoleTable("LegalInsightsCrudContext: aprovacaoReprovar", error.response)

            if(error.response.status === 400 & error.response.data.message === 'Erro durante a reprovação'){
                showToast('info', aprovacoesMensagem.ERRO_DURANTE_REPROVACAO)
            }
             
            if(error.response.status === 400 & error.response.data.message != 'Erro durante a reprovação'){
                showToast('info', error.response.data.message)
            }

        }
                
        
    }

    const aprovacaoForcarPendenteAprovacao = async (numeroProcesso, observacoes) => {
        const tokenUsuario = {
            Email: user.email,
            TokenUsuario: chaveRLANET
        }
        const jsonBase64 = convertToBase64(tokenUsuario)
                
        try {
            const response = await axios.post(`${api}/aprovacoes/ForcarPendenteAprovacao?numeroProcesso=${numeroProcesso}&observacao=${observacoes}&tokenUsuario=${jsonBase64}`)
            return response
        } catch (error) {
            console.log("Error: aprovacaoForcarPendenteAprovacao: ",error)
        }
    }

    const aprovacoesConcluidas = async () => {
        const tokenUsuario = {
            Email: user.email,
            TokenUsuario: chaveRLANET
        }
        const jsonBase64 = convertToBase64(tokenUsuario)        
        try {
            const response = await axios.get(`${api}/aprovacoes/aprovacoesConcluidas?tokenUsuario=${jsonBase64}`)

            if(response?.status == 200 & response.data.status != 500 ){
                let _aprovacoesConcluidas = []
                
                response?.data?.map( (item, index)=>{
                    _aprovacoesConcluidas.push({
                        id: index,
                        calcDoc: item.calcDoc,
                        discDoc: item.discDoc,
                        processoID: item.processoID,
                        processoNr: item.processoNr,
                        fase: item.fase,
                        valorContingenciaAtual: item.valorContingenciaAtual,
                        valorMeta: item.valorMeta,
                        valorAcordo: item.valorAcordo,
                        valorAnalise: item.valorAnalise,
                        desagio: item.desagio,
                        valorAcordoLiquido: item.valorAcordoLiquido,
                        inssReclamada: item.inssReclamada,
                        custasProcessuais: item.custasProcessuais,
                        irrf: item.irrf,    
                        aprovado: false,
                        cancelado: false,
                        documentos: [{ link: item?.calcDoc, nomeDocumento: 'Cálculo'}, { link: item?.discDoc, nomeDocumento: 'Discriminação' }],
                        statusAprovacao: item.statusAprovacao,
                        checklist: item.checklist,
                        grupo: item.grupo,
                        grupoID: item.grupoID,
                        contraparte: item.contraparte,
                        momentoProcessual: item.momentoProcessual,
                        advogadoContraparte: item.advogadoContraparte,
                        valorProvisaoAtualizado: item.valorProvisaoAtualizado,
                        dataDecisaoCliente: item.dataDecisaoCliente
                    })
                })

                setApiAprovacoesConcluidas(_aprovacoesConcluidas)    
            }else{
                setApiAprovacoesConcluidas([])
            }

        } catch (error) {
            console.table([{metodo: 'aprovacoesConcluidas: ', statusCode: error.statusCode? error.statusCode : error.response.status? error.response.status : 500, data: error.response.data? error.response.data.message : error.data?.message }]) 
            setApiAprovacoesConcluidas([])
        }        
        
    }

    const getPowerBIPortal = async (moduloPerfil) => {        
        setLoading(true)        
        const payload = {
            email: user.email,
            moduloPerfil: moduloPerfil
        }

        try{
            const _usuarioTemPowerBI = await axios.post(`${api}/legalInsightsDashboards/validaSeUsuarioTemPerfilNoPowerBI`, user).then( responseTemPortal => {
                if(responseTemPortal?.status === 200){
                    setUsuarioTemPowerBI(true)
                    return true
                }else{ 
                    setUsuarioTemPowerBI(true)
                    return false
                }                
            })

            if(_usuarioTemPowerBI === true){
                await axios.post(`${api}/legalInsightsDashboards/getConfigPortal`, payload)
                .then( responseGetConfigPortal => {
                    
                    if(responseGetConfigPortal?.status === 200 && responseGetConfigPortal?.data?.length > 0){
                        
                        let payload = {
                            customerId: responseGetConfigPortal.data[0].CustomerId,
                            idPerfil: responseGetConfigPortal.data[0].IdPerfil,
                        }
                        axios.post(`${api}/legalInsightsDashboards/GetPbiEmbed`, payload)
                        .then( responseGetPbiEmbed => {
                            if(responseGetPbiEmbed?.status === 200){
                                responseGetPbiEmbed.data.CodigoPagina = responseGetConfigPortal.data[0].CodigoPagina
                                responseGetPbiEmbed.data.TITULO = responseGetConfigPortal.data[0].TITULO
                                setGetConfigPortal(responseGetPbiEmbed.data)
                                setStatusReportsAcesso(true)
                            }                        
                        })
                        
                        payload.tipo = "G"
                        payload.perfil = 0
                        axios.post(`${api}/legalInsightsDashboards/getPreFiltro`, payload)
                        .then( getPreFiltroG => {
                            if(getPreFiltroG?.status === 200 && getPreFiltroG.data?.data != ''){
                                setFilters((prevFilters)=> [...prevFilters, JSON.parse(getPreFiltroG.data.data)])
                               
                               payload.tipo = "P"                                                    
                               axios.post(`${api}/legalInsightsDashboards/getPreFiltro`, payload)
                               .then( getPreFiltroP => {
           
                                    if(getPreFiltroP?.status === 200  && getPreFiltroP.data.data != ''){
                                        setFilters((prevFilters)=> [...prevFilters, getPreFiltroP.data])
                                        payload.tipo = "U"
                                        payload.userId = responseGetConfigPortal.data[0].UserId
                                        axios.post(`${api}/legalInsightsDashboards/getPreFiltro`, payload)
                                        .then( getPreFiltroU => {
                   
                                            if(getPreFiltroU?.status === 200  && getPreFiltroU.data.data != ''){
                                                setFilters((prevFilters)=> [...prevFilters, getPreFiltroU.data])
                                            }                        
                                       })
                                   }                        
                               })
                            }                        
                        })
                    }else{
                        setStatusReportsAcesso(false)
                    }
                }) 
            }   
            setLoading(false)
        }catch(error){
            console.log("Error: getPowerBIPortal :(||", error)
            setLoading(false)
        }
    }

    const getProjetosPorUsuario = async ( ) =>{
        const tokenUsuario = {
            Email: user.email,
            TokenUsuario: chaveRLANET
        }
        let _projetosPorUsuario = []

        const jsonBase64 = convertToBase64(tokenUsuario)        

        try {       
            const response = await axios.get(`${api}/aprovacoes/ProjetosPorUsuario?tokenUsuario=${jsonBase64}`) 

            
            if(response?.status == 200 & response.status != 500 ){
                response.data.map( item =>{
                    _projetosPorUsuario.push({
                        value: item.grupoID,
                        label: item.grupo
                    })                    
                })

                setApiAprojetos(_projetosPorUsuario)
            }
        } catch (error) {
            consoleTable("getProjetosPorUsuario", error.response)    
            setApiAprojetos([])            
        }
    }

    const alterColumnVisibility = (model) => {
        setColumnVisibilityModel(model);
    }

    const criarUsuario = async (payload) => {
        try {

            const _payload = {
                tipoUsuario: tipoUsuario.filter( (item) =>  item.value === payload.tipoUsuario  )[0].label ,
                nomeCliente: payload.nomeCliente,
                cnpj: payload.cnpj,
                nomeCompletoUsuario: payload.nomeCompleto,
                emailUsuario: payload.email,
                observacoes: "",
                avatarUrl: "",
                perfil:{
                    aprovador: payload.usuarioAprovador,
                    powerBI: payload.acessoModuloProjetoAcordo,
                    acessoMenuStatusReports: payload.acessoModuloProjetoAcordo,                    
                    acessoModuloSeguros: payload.acessoModuloSeguros,
                    acessoModuloProjetoAcordo: payload.acessoModuloProjetoAcordo,
                    acessoModuloPainelGestao: payload.acessoModuloGestaoDados,
                    acessoModuloDepositoJudiciais: payload.acessoModuloDepositoJudiciais,
                },
                projetoGrupo: payload.projeto?.length === 0? ['(SEM)'] : payload.projeto
            }

            const response = await axios.post(`${api}/user/criarNovoUsuario`, _payload,  {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })

            if(response.status === 200){
                showToast('success','Usuário criado com sucesso!')
            }

            return response

        } catch (error) {

            if(error?.response?.status === 400){
                showToast('info',`${error?.response?.data?.message}`)
            }

            console.table([{metodo: 'criarUsuario: ', statusCode: error.statusCode? error.statusCode : error.response.status? error.response.status : 500, data: error.response.data? error.response.data.message : error.data?.message }]) 
        }
    }

    const criarUsuarioEmLote = async (payload) => {
        try {
        const _payload = {
            usuarios: payload
        }

        const response = await axios.post(`${api}/user/criarUsuarioEmLote`, _payload,  {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })

        if(response.status === 200){
            showToast('success','Dados sendo processado em background, aperte F5 para atualizar sua página!')
        }

        return response

    } catch (error) {

        if(error?.response?.status === 400){
            showToast('info',`${error?.response?.data?.message}`)
        }

        console.table([{metodo: 'criarUsuarioEmLote: ', statusCode: error.statusCode? error.statusCode : error.response.status? error.response.status : 500, data: error.response.data? error.response.data.message : error.data?.message }]) 
    }}

    const salvarArquivoAws = async (formData, payloadData) =>{
        try{
            const _formData = new FormData()
            _formData.append('file', formData)
            _formData.append('baseCliente', payloadData.baseCliente)
            _formData.append('nomeUsuario', payloadData.nomeUsuario)
            _formData.append('tipoArquivo', payloadData.tipoArquivo)
            _formData.append('modulo', payloadData.modulo? payloadData.modulo : "" )
            _formData.append('processo', payloadData.processo? payloadData.processo : "" )
            _formData.append('nomeColunaGrid', payloadData.nomeColunaGrid? payloadData.nomeColunaGrid : "" )

            const response = await axios.post(`${api}/arquivos/criarArquivo`, _formData, {
                headers: {
                    'Authorization': `token ${user.token}`,
                    'Content-Type': 'multipart/form-data'
                }
            })   
            return response        
        }catch(error){
            console.table([{metodo: 'salvarArquivoAws: ', statusCode: error.statusCode? error.statusCode : error.response.status? error.response.status : 500, data: error.response.data? error.response.data.message : error.data?.message }]) 
            showToast('warning', error.response.data.message)
        }
    }

    const donwloadAzureStorageBlod = async (accountName, containerName, blobName) => {
        try {
            const payload ={
                accountName: accountName,
                containerName: containerName,
                blobName: blobName
            }

            const response = await axios.post(`${api}/arquivos/donwloadAzureStorageBlod`, payload, {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })   

            if(response.status === 200){
                return response.data
            }

            if(response.status != 200){
                return showToast('info',"Download do arquivo não realizado. Por favor, entre em contato com o administrador do sistema.")
            }

             
        } catch (error) {
            console.table([{metodo: 'donwloadAzureStorageBlod: ', statusCode: error.statusCode? error.statusCode : error.response.status? error.response.status : 500, data: error.response.data? error.response.data.message : error.data?.message }]) 
        }
    }

    const buscaTodosArquivos = async () => {
        try {
            const response = await axios.get(`${api}/arquivos/buscaTodosArquivos`, {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            }) 
            
            if(user.tipoUsuario === 'Administrador Global'){
                setArquivos(response.data)
            }

            if(user.tipoUsuario != 'Administrador Global'){
                const filterArquivos = response.data.filter( item => item.usuario === user.nome)
                setArquivos(filterArquivos)
            }

            
                
        } catch (error) {
            console.table([{metodo: 'buscaTodosArquivos: ', statusCode: error.statusCode? error.statusCode : error.response.status? error.response.status : 500, data: error.response.data? error.response.data.message : error.data?.message }]) 
        }
    }

    const listarModulos = async () => {
        try {
            const modulos = await axios.get(`${api}/modulo/all`,{
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })

            if(modulos.status === 200){
                setModulo(modulos.data)
            }            

        } catch (error) {
            console.table([{metodo: 'listarModulos: ', statusCode: error.statusCode? error.statusCode : error.response.status? error.response.status : 500, data: error.response.data? error.response.data.message : error.data?.message }]) 
        }
    }

    const buscaArquivoPorIdProcessoENomeColuna = async (processo, nomeColuna) => {
        try {
            const response = await axios.post(`${api}/arquivos/buscaArquivoPorIdProcessoENomeColuna`, 
                { processo: processo, nomeColuna: nomeColuna },
                {
                    headers: {
                        'Authorization': `token ${user.token}`
                    },
                }
            ) 

            if(response.status === 200) return response.data

            if(response.status !== 200) return showToast('warning', 'Não foi possível realizar o download do seu arquivo, favor contar o administrador do sistema!')            
                      
                
        } catch (error) {
            console.table([{metodo: 'buscaTodosArquivos: ', statusCode: error.statusCode? error.statusCode : error.response.status? error.response.status : 500, data: error.response.data? error.response.data.message : error.data?.message }]) 
        }
    }


    return (
        <LegalInsightsCrudContext.Provider value={{      
            //PROPS
                stepEtapa, setStepEtapa,
                showMenuConfiguracoes, setShowMenuConfiguracoes,
                showMeuPerfil, setShowMeuPerfil,
                menuSelecionado, setMenuSelecionado,
                loadingDownload, setLoadingDownload,
                loading, setLoading,
                apiCidades, setApiCidades,
                apiAprovacoesPendentes, setApiAprovacoesPendente,
                apiProjetos, setApiAprojetos,
                apiAprovacoesConcluidas, setApiAprovacoesConcluidas,    
                apiGetConfigPortal,            
                observacoes, setObservacoes,
                columnVisibilityModel, setColumnVisibilityModel,
                statusReportsAcesso, setStatusReportsAcesso,
                filters,
                showModalConfiguracoes, setShowModalConfiguracoes,
                arquivoUpload, setArquivoUpload,
                projetosRLANET,setProjetosRLANET,
                usuarioTemPowerBI, 
                arquivos,
                modulos,
                moduloSelecionado, setModuloSelecionado,

            //MÉTODOS
                enviarCodigoSeguranca,
                validarCodigoSeguranca,
                alterPasswordLegalInsights,
                createUserLegalInsights,
                usuarioExistente,
                consultarProjetosRLANET,
                aprovacaoAprovacoesPendentes,
                aprovacaoAprovar,
                aprovacaoReprovar,
                aprovacaoForcarPendenteAprovacao,
                aprovacoesConcluidas,
                getPowerBIPortal,
                getProjetosPorUsuario,
                alterColumnVisibility,
                criarUsuario,
                criarUsuarioEmLote,
                salvarArquivoAws,
                donwloadAzureStorageBlod,
                buscaTodosArquivos,
                listarModulos,
                buscaArquivoPorIdProcessoENomeColuna
        }}>
            {children}
        </LegalInsightsCrudContext.Provider>
    )
}

LegalInsightsProvider.propTypes = {
    children: PropTypes.node
}

export default LegalInsightsProvider;