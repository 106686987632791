import React from 'react'

import './index.css'

//Icons
import { RiUserLine } from 'react-icons/ri'
import { FiLogOut } from 'react-icons/fi'
import { RxAccessibility } from "react-icons/rx"


import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../../../contexts/LegalInsights/auth'
import { LegalInsightsCrudContext } from '../../../contexts/LegalInsights/LegalInsightsCrudContext'
import { getModuloLocalStorage } from '../../../utils/data'




const ShowModalConfiguracoesItens =() =>{
    const { signOut, user } = useContext(AuthContext)
    const { showModalConfiguracoes, setShowModalConfiguracoes } = useContext(LegalInsightsCrudContext)
    const moduloSelecionado = getModuloLocalStorage()

    const closeModal =()=>{
        setShowModalConfiguracoes(!showModalConfiguracoes)
    }

    return(<div className='configuracoesItem'>

        {user.tipoUsuario === "Administrador Global" &&
        <>

            {moduloSelecionado === 'Projeto Acordo' && <>
            
                <Link to={'/projetoAcordo/controleAcesso'} className='configuracoes-item' onClick={closeModal} >
                    <div className='item'>
                    <RxAccessibility />
                    <span>Controle de acesso</span>
                    </div>
                    <hr />
                </Link>            
            </>}
            
            {moduloSelecionado === 'Deposito Judiciais' && <>

                <Link to={'/depositoJudicial/usuario'} className='configuracoes-item' onClick={closeModal} >
                    <div className='item'>
                    <RiUserLine />
                    <span>Cadastro de Usuário</span>
                    </div>
                    <hr />
                </Link>
                
                <Link to={'/depositoJudicial/cadastroFee'} className='configuracoes-item' onClick={closeModal} >
                    <div className='item'>
                    <RxAccessibility />
                    <span>Cadastrar Fee Legal</span>
                    </div>
                    <hr />
                </Link>

                <Link to={'/depositoJudicial/cadastroStatusFinal'} className='configuracoes-item' onClick={closeModal} >
                    <div className='item'>
                    <RxAccessibility />
                    <span>Cadastro de Status Final</span>
                    </div>
                    <hr />
                </Link>
                
                <Link to={'/depositoJudicial/cadastroEmpresa'} className='configuracoes-item' onClick={closeModal} >
                    <div className='item'>
                    <RxAccessibility />
                    <span>Cadastro de Empresa</span>
                    </div>
                    <hr />
                </Link>

            </>}            
        </>        
        }

        <Link to={''} className='configuracoes-item' onClick={ ()=> signOut()} >
            <div className='item'   >
                <FiLogOut />
                <span>Sair</span>
            </div>
            <hr />
        </Link>
    </div>)
}

export default ShowModalConfiguracoesItens