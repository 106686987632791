import React, { useContext, useEffect, useState} from 'react'


import MenuLatel from '../../../../Components/Commons/MenuLateral'
import { menuLateralOpcoesPorModulos } from '../../../../utils/Compartilhados'


import './index.css'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'
import { LegalInsightsCrudContext } from '../../../../contexts/LegalInsights/LegalInsightsCrudContext'
import Header from '../../../../Components/Commons/Header'
import DataGridCustom from '../../../../Components/Commons/DataGrid/DataGridCustom'
import { Box } from '@mui/material'
import ReactLoading from 'react-loading'
import useForm from '../../../../hooks/useForm'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { ButtonLabelComponent } from '../../../../Components/Commons/Button'
import { DepositoJudicialContext } from '../../../../contexts/ContextDepositoJudicial/DepositoJudicialContext'
import GridActionsCellItemDelete from '../../../../Components/Commons/GridActionsCellItemDelete'




const CadastroStatusFinal = () => {
    const { showMenuConfiguracoes, showMeuPerfil, alterColumnVisibility, columnVisibilityModel, 
        moduloSelecionado
    } = useContext(LegalInsightsCrudContext) 
    const { user, usuarioConectados, load } = useContext(AuthContext)
    const [_statusFinal, _setStatusFinal ] = useState([])    
    const [showModal, setShowModal] = useState(false)
    const [form, handleInputChange, clear] = useForm({
      statusFinal: '',
      aba: ''
    }
    )
    const { salvarStatusFinal, getStatusFinal, statusFinal, alterarStatsFinal, deleteStatsFinal } = useContext(DepositoJudicialContext)


    useEffect(()=>{  
      usuarioConectados()  
      getStatusFinal()
      alterColumnVisibility({ id: false }) 
    },[])

    useEffect(()=>{ 
        if(statusFinal?.length === 0 || statusFinal === undefined){ 
            _setStatusFinal([])
        }else{
            _setStatusFinal(statusFinal) 
        }
    },[statusFinal])


    const columns = [
      {
          field: "id",
          headerName: "id",
          hide: columnVisibilityModel?.id,
      },
      { field: 'actions',
        type: 'actions',
        headerName: 'Deletar registro',
        width: 150,
        cellClassName: 'actions',
        getActions: ({ id }) => GridActionsCellItemDelete({ idGrid: id, updateFunction: deleteStatsFinal, refreshFunction: getStatusFinal })
      },

      {
          field: "label",
          headerName: "Status final",
          width: 450,
          editable: true,
          renderEditCell: (params)=> <EditTexto {...params} />
      },
      {
          field: "aba",
          headerName: "Identificação/Levantamento",
          width: 250,
          editable: false,
      }
      ]


  const EditTexto = (params) => {
    const [value, setValue] = React.useState(params.value);

    const handleChange = (event) => {
      setValue(event.target.value)
    };

    const handleBlur = async (event) => {
      const newValue = event.target.value
      params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue }, event)
      await alterarStatsFinal({ id: params.id, statusFinal: newValue })
      await getStatusFinal()
    }

    return (
      <input
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{ 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%', 
          height: '100%', 
          border: 'none', 
          textAlign: 'center'
        }}
        className='percentual-cell'
      />
    )
  }

  const onHideModal = () => {
    setShowModal(!showModal)
    clear()
  }

  const salvar = async () => {
    const payload = {
      descricao: form.statusFinal,
      aba: form.aba === 1? 'DepositoJudicial_levantamento' : 'DepositoJudicial_substituicao'
    }

    await salvarStatusFinal(payload)
    setShowModal(!showModal)
    getStatusFinal()
    clear()
  }

  const showModalCadastroStatusFinal = () => {
    return(<Dialog
      visible={showModal}
      style={{ width: '450px', height: '350px' }}
      header="Cadastro Status Final"
      modal
      className='show-modal-cadastro-novo-usuario'
      onHide={onHideModal}
      footer={
        <React.Fragment>
          <div className="dialog-show-modal-cadastro-novo-usuario footer">
            <ButtonLabelComponent label={`Salvar`} onClick={salvar} /> 
              <ButtonLabelComponent label="Cancelar" icon="pi pi-times-circle" onClick={onHideModal} info={'info'} /> 
          </div>  
        </React.Fragment>                  
      }
    >
        <div className="dialog-show-modal-cadastro-novo-usuario-unico form">
          <Dropdown className='perfil-tipoUsuario' name='aba' placeholder="Aba" value={form.aba} 
            options={[{ value: 1, label: 'Levantamento'}, { value: 2, label: 'Substituição'}]} 
            onChange={handleInputChange} 
          />
          <InputText type='text' className='input-textLabel' name='statusFinal' placeholder='Status Final' value={form.statusFinal} onChange={handleInputChange} />
        </div>          
    </Dialog>)
  } 

  const rightToolbarTemplate = () => {
      return (
        <React.Fragment>          
          <ButtonLabelComponent label={'Novo'} icon="pi pi-plus" onClick={onHideModal}/>                                    
        </React.Fragment>
      )
  }

  const handleProcessRowUpdate = (newRow) => {
    return newRow
  };

  return(
    <div className="modulo-projeto-acordos-usuario">
      <Header gotoPagina={'/depositoJudicial/statusReport'} /> 
        <div className="projeto-acordo-container">
          <div className="projeto-acordo-menu-left">
            <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos(moduloSelecionado, user?.perfil)}/>
          </div>
              <div className="projeto-acordo-conteudo">
                <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >
                     <Toolbar className="mb-4" left={<span className='page-title'>Cadastro Status Final</span>} right={rightToolbarTemplate}></Toolbar>

                    <div className="data-grid-meus-processos">
                      {load? 
                          <div className="loadProcessos">
                              <ReactLoading type='spin' color='#4e1975' />
                          </div> 
                      :
                          <Box sx={{ height: '80vh', width: '100%' }}>
                              <DataGridCustom                                             
                                  column={columns} 
                                  rows={_statusFinal} 
                                  selection={false}   
                                  processRowUpdate={handleProcessRowUpdate}                                        
                              />
                          </Box>
                      }
                    </div>
                </div>
              </div>

              {showModal && showModalCadastroStatusFinal()}

        </div>
    </div>
  )
}

export default CadastroStatusFinal
