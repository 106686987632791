import * as React from 'react';
import PropTypes from 'prop-types'
import { Dropdown } from 'primereact/dropdown';

import './index.css';

export const SelectLabels = ({ itens, name, label, onChange }) => {
  return (
    <div className="form_select_labels">
      <select name="" id="select_options" placeholder={label} onChange={onChange}>
          <option value="" disabled selected hidden>{label}</option>
          <option value="todos" name={name}>...Todos...</option>
          {itens?.map( (item, index)=> <option key={index} value={item.value}>{item.label}</option> )}        
      </select>      
    </div>
  );
};

SelectLabels.propTypes = {
  itens: PropTypes.array,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func
}

export const DrropdowPadrao = ({ onChange, options, optionLabel, value, label, style }) => {
  return (
    <div className="card flex justify-content-center">
      <span className="p-float-label" style={{ position: 'relative' }}>
        <label htmlFor="dd-city" style={{ position: 'absolute', top: -15 }}>
          {label}
        </label>
        <Dropdown
          inputId="dd-city"
          value={value}
          onChange={onChange}
          options={options}
          optionLabel={optionLabel}
          className={`w-full md:w-${style? style : '14rem'}`}
          name={optionLabel}
        />
      </span>
    </div>
  );
};

DrropdowPadrao.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  optionLabel: PropTypes.string,
  value: PropTypes.number,
  label: PropTypes.string,
  style: PropTypes.string,
}