import React, { useContext, useEffect, useState} from 'react'


import MenuLatel from '../../../../Components/Commons/MenuLateral'
import { menuLateralOpcoesPorModulos } from '../../../../utils/Compartilhados'


import './index.css'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'
import { LegalInsightsCrudContext } from '../../../../contexts/LegalInsights/LegalInsightsCrudContext'
import Header from '../../../../Components/Commons/Header'
import DataGridCustom from '../../../../Components/Commons/DataGrid/DataGridCustom'
import { Box } from '@mui/material'
import ReactLoading from 'react-loading'
import { ButtonLabelComponent } from '../../../../Components/Commons/Button'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import useForm from '../../../../hooks/useForm'
import { InputText } from 'primereact/inputtext'
import { DepositoJudicialContext } from '../../../../contexts/ContextDepositoJudicial/DepositoJudicialContext'
import { showToast } from '../../../../utils/Compartilhados/CustomToast'
import GridActionsCellItemDelete from '../../../../Components/Commons/GridActionsCellItemDelete'



const CadastroEmpresa = () => {
  const { 
    showMenuConfiguracoes, 
    showMeuPerfil, 
    alterColumnVisibility, 
    columnVisibilityModel, 
    moduloSelecionado
  } = useContext(LegalInsightsCrudContext) 
  const {getEmpresa, empresa, salvarEmpresa, alterarEmpresa, deleteEmpresa } = useContext(DepositoJudicialContext)
  const { user, load } = useContext(AuthContext) 
  const [showModal, setShowModal] = useState(false)
  const [_empresa, _setEmpresa] = useState([])
  const [form, handleInputChange, clear] = useForm({
    empresa: ''
  }
  )

  useEffect(()=>{   
    getEmpresa() 
    alterColumnVisibility({ id: false }) 
  },[])

  useEffect(()=>{  
    if(empresa?.length === 0 || empresa === undefined){
      _setEmpresa([]) 
    }else{
      _setEmpresa(empresa)
    }

  },[empresa])


  const columns = [
    {
        field: "id",
        headerName: "id",
        hide: columnVisibilityModel?.id,
    },
    { field: 'actions',
      type: 'actions',
      headerName: 'Deletar registro',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => GridActionsCellItemDelete({ idGrid: id, updateFunction: deleteEmpresa, refreshFunction: getEmpresa })
    },
    {
        field: "label",
        headerName: "Empresa",
        width: 500,
        editable: true,
        renderEditCell: (params)=> <EditTexto {...params} />
    }
  ]

  const rightToolbarTemplate = () => {
      return (
        <React.Fragment>          
          <ButtonLabelComponent label={'Novo'} icon="pi pi-plus" onClick={onHideModal}/>                                    
        </React.Fragment>
      )
  }

  const showModalCadastroFee = () => {
      return(<Dialog
        visible={showModal}
        style={{ width: '450px', height: '250px' }}
        header="Cadastro de Empresa"
        modal
        className='show-modal-cadastro-novo-usuario'
        onHide={onHideModal}
        footer={
          <React.Fragment>
            <div className="dialog-show-modal-cadastro-novo-usuario footer">
              <ButtonLabelComponent label={`Salvar`} onClick={salvar} /> 
               <ButtonLabelComponent label="Cancelar" icon="pi pi-times-circle" onClick={onHideModal} info={'info'} /> 
            </div>  
          </React.Fragment>                  
        }
      >
          <div className="dialog-show-modal-cadastro-novo-usuario-unico form">
            <InputText type='text' className='input-textLabel' name='empresa' placeholder='Empresa' value={form.empresa} onChange={handleInputChange} />
          </div>          
      </Dialog>)
  }     
  
  const onHideModal= () => {
    setShowModal(!showModal)
    clear()
  }

  const salvar = async () => {
    const empresaJaCadastrada = empresa.some(e => e.label === form.empresa)
  
    if (empresaJaCadastrada) {
      showToast('warning', 'Empresa já cadastrada')
      return
    }
  
    try {
      await salvarEmpresa({ empresa: form.empresa })
  
      onHideModal();
    } catch (error) {
      showToast('error', 'Erro ao salvar empresa, tente novamente.')
    }
  }

  const EditTexto = (params) => {
    const [value, setValue] = React.useState(params.value || ""); // Certifique-se de que params.value tem um valor padrão
  
    const handleChange = (event) => {
      setValue(event.target.value);
    };
  
    const handleBlur = async (event) => {
      const newValue = event.target.value;
  
      // Verifique se params e params.api existem antes de usar
      if (params && params.api && params.id && params.field) {
        try {
          // Atualiza o valor da célula
          params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue }, event);
  
          // Atualiza no backend
          await alterarEmpresa({ id: params.id, empresa: newValue });
  
          // Recarrega os dados da grid
          getEmpresa();
        } catch (error) {
          console.error("Erro ao atualizar a célula:", error);
        }
      } else {
        console.error("Parâmetros inválidos fornecidos para handleBlur:", params);
      }
    };
  
    return (
      <input
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{ 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%', 
          height: '100%', 
          border: 'none', 
          textAlign: 'center'
        }}
        className='percentual-cell'
      />
    );
  };
  
  
  const handleProcessRowUpdate = (newRow) => {
    return newRow
  };


    return(
      <div className="modulo-projeto-acordos-usuario">
        <Header gotoPagina={'/depositoJudicial/statusReport'} /> 
          <div className="projeto-acordo-container">
            <div className="projeto-acordo-menu-left">
              <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos(moduloSelecionado, user?.perfil)}/>
            </div>
                <div className="projeto-acordo-conteudo">           
                  <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >
                    <Toolbar className="mb-4" left={<span className='page-title'>Cadastro de Empresa</span>} right={rightToolbarTemplate}></Toolbar>

                      <div className="data-grid-meus-processos">
                        {load? 
                            <div className="loadProcessos">
                                <ReactLoading type='spin' color='#4e1975' />
                            </div> 
                        :
                            <Box sx={{ height: '80vh', width: '100%' }}>
                                <DataGridCustom                                             
                                    column={columns} 
                                    rows={_empresa} 
                                    selection={false}                                                       
                                    processRowUpdate={handleProcessRowUpdate}
                                />
                            </Box>
                        }
                      </div>

  
                  </div>
                </div>

                {showModal && showModalCadastroFee()}
          </div>
      </div>
    )
}

export default CadastroEmpresa
